$dark-primary-paper: #292f32;
$dark-primary-light: #464c51;
$dark-primary-main: #2d3438;
$dark-primary-dark: #292f32;
$dark-secondary: #bb86fc;
// $dark-secondary: #42C56A;
$dark-text-primary: rgba(255, 255, 255, 0.87);
$dark-text-secondary: rgba(255, 255, 255, 0.6);
$dark-text-tertiary: rgba(255, 255, 255, 0.49);
$dark-text-disabled: rgba(255, 255, 255, 0.38);

$primary-color-100: #fed1bf;
$primary-color-300: #df967a;
$primary-color-500: #a95939;
$primary-color-700: #752c0f;
$primary-color-900: #401301;
$primary-color-1100: #2b1301;

$secondary-color-100: #8fa8bb;
$secondary-color-300: #527590;
$secondary-color-500: #29506d;
$secondary-color-700: #0d2f4b;
$secondary-color-900: #021728;

$green-200: #a5d6a7;

$gray: #4a5155;
$light-gray: #6d7579;

:export {
  darkPrimaryPaper: $dark-primary-paper;
  darkPrimaryLight: $dark-primary-light;
  darkPrimaryMain: $dark-primary-main;
  darkPrimaryDark: $dark-primary-dark;
  darkSecondary: $dark-secondary;
  darkTextPrimary: $dark-text-primary;
  darkTextSecondary: $dark-text-secondary;
  darkTextTertiary: $dark-text-tertiary;
  darkTextDisabled: $dark-text-disabled;
  primary100: $primary-color-100;
  primary300: $primary-color-300;
  primary500: $primary-color-500;
  primary700: $primary-color-700;
  primary900: $primary-color-900;
  primary1100: $primary-color-1100;

  secondary100: $secondary-color-100;
  secondary300: $secondary-color-300;
  secondary500: $secondary-color-500;
  secondary700: $secondary-color-700;
  secondary900: $secondary-color-900;

  gray: $gray;
  lightGray: $light-gray;
}
