@import "./colors.scss";
@import "./carousel.css";
@import "./fonts.scss";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

html {
  height: -webkit-stretch; /* https://twitter.com/bfgeek/status/1262465912025698304 */
}

html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%; /* We keep this right? */
  min-height: 100vh;
  min-height: -webkit-stretch; /* We want the vendor-prefixed value to take affect
                                         in browsers that support it, fall back to 100vh,
                                         then 100%. */
}

#root {
  height: -webkit-stretch; /* https://twitter.com/bfgeek/status/1262465912025698304 */
  min-height: 100%; /* We keep this right? */
  min-height: 100vh;
  min-height: -webkit-stretch; /* We want the vendor-prefixed value to take affect
                                         in browsers that support it, fall back to 100vh,
                                         then 100%. */
}

#nexus {
  height: -webkit-stretch; /* https://twitter.com/bfgeek/status/1262465912025698304 */
  min-height: 100%; /* We keep this right? */
  min-height: 100vh;
  min-height: -webkit-stretch; /* We want the vendor-prefixed value to take affect
                                         in browsers that support it, fall back to 100vh,
                                         then 100%. */
}

.full-height {
  height: -webkit-stretch; /* https://twitter.com/bfgeek/status/1262465912025698304 */
  min-height: 100%; /* We keep this right? */
  min-height: 100vh;
  min-height: -webkit-stretch; /* We want the vendor-prefixed value to take affect
                                         in browsers that support it, fall back to 100vh,
                                         then 100%. */
}

/* hide scrollbar but allow scrolling */
.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

span {
  user-select: none;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flx {
  display: flex;
}

.cl {
  flex-direction: column;
}

.rw {
  flex-direction: row;
}

.vc {
  justify-content: center;
}

.hc {
  align-items: center;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-container-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skeleton-pulse {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    -90deg,
    $dark-primary-light 0%,
    rgb(64, 74, 80) 50%,
    $dark-primary-light 100%
  );
  background-size: 400% 400%;
  animation: skeletonPulse 1.2s ease-in-out infinite;

  @keyframes skeletonPulse {
    0% {
      background-position: 0% 0%;
    }

    100% {
      background-position: -135% 0%;
    }
  }
}

.skeleton-line {
  @extend .skeleton-pulse;
  width: 5.5em;
  border-radius: 5px;

  &::before {
    content: "\00a0";
  }
}

.invisible {
  display: none;
}

.visible {
  display: inherit;
}

.no-scrollbars {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.no-scrollbars::-webkit-scrollbar {
  width: 0 !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hoverable {
  @media (hover: hover) and (pointer: fine) {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
    &:focus {
      opacity: 1;
    }
    &:active {
      opacity: 1;
    }
  }
}

.slick-slider {
  z-index: 0;
}

.no-highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
