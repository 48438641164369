.block {
  position: relative;
  margin: 0 auto;
  width: 900px;
  height: 631px;
  background: linear-gradient(0deg, #000, #272727);
  z-index: 1;
}

.block:before,
.block:after {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  background: linear-gradient(
    45deg,
    #15ca7b,
    #4c9ddc,
    #15ca7b,
    #0e784a,
    #15ca7b,
    #4c9ddc,
    #15ca7b,
    #4c9ddc,
    #295476,
    #4c9ddc
  );
  background-size: 400%;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  z-index: -1;
  border-radius: 4px;
  animation: steam 60s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.block:after {
  filter: blur(25px);
}
